<template>
  <Column class="agencies-guides__agency grid-sm-full grid-md-6 grid-lg-6">
    <Icon v-on:click.native="backToAllAgencies"
          class="agencies-guides__icon-back"
          xlink="back"
          viewport="0 0 30 30"/>
    <p class="agencies-guides__agency-title">{{$operators.agency && $operators.agency.title}}</p>
    <Row justify="between">
      <Rating :params="{id: $operators.agency &&  $operators.agency.id,
                      type: 'agencies',
                      rating: $operators.agency && $operators.agency.rating,
                      currentUserRating: $operators.agency
                      && $operators.agency.currentUserRating}"/>
    </Row>
    <Column class="agencies-guides__agency-additions">
      <Column class="agencies-guides__agency-addition">
        <p class="agencies-guides__agency-additions-title">{{ $t('agencies.address') }}</p>
        <p class="agencies-guides__agency-additions-value">{{$operators.agency
          && $operators.agency.address}}</p>
      </Column>
      <Column class="agencies-guides__agency-addition">
        <p class="agencies-guides__agency-additions-title">{{ $t('agencies.phone') }}</p>
        <p class="agencies-guides__agency-additions-value">{{$operators.agency
          && $operators.agency.phone}}</p>
      </Column>
      <Column class="agencies-guides__agency-addition">
        <p class="agencies-guides__agency-additions-title">{{ $t('agencies.schedule') }}</p>
        <p class="agencies-guides__agency-additions-value">{{$operators.agency
          && $operators.agency.mode}}</p>
      </Column>
      <Column class="agencies-guides__agency-addition">
        <p class="agencies-guides__agency-additions-title">{{ $t('agencies.about') }}</p>
        <div class="agencies-guides__agency-additions-value html-insert"
             v-html="$operators.agency
             && $operators.agency.description">
        </div>
      </Column>
    </Column>
    <div class="agencies-guides__slider">
      <Slider v-if="trips && trips.length > 0"
              :indentation="$laptop ? 30 : 8"
              :count="1"
              :items="trips"
              :height="400"
              component="TripCard"
              limiter/>
    </div>
  </Column>
</template>

<script>
export default {
  name: 'AgenciesGuidesAgency',
  data() {
    return {
      trips: [],
    };
  },
  created() {
    this.$http.get(`/api/agencies/${this.$operators.agency.id}&resolution=medium`)
      .then((resolve) => {
        this.trips = resolve.data.data.trips;
      });
  },
  methods: {
    backToAllAgencies() {
      this.$store.commit('CLEAR_AGENCY');
    },
  },
};
</script>
